import Image from "next/image"
import Helmet from "../lib/helpers/Helmet"

const NotFoundPage = () => {
  return (
    <>
      <Helmet title="Harisenin.com: Not Found" />
      <div className="error_page">
        <div className="error_page__left">
          <Image
            src="https://nos.jkt-1.neo.id/harisenin-storage/public/assets/pages/error-page_asset.png"
            alt="image"
            width={568}
            height={400}
          />
        </div>
        <div className="error_page__right">
          <Image
            src="https://nos.jkt-1.neo.id/harisenin-storage/public/assets/pages/error-page_status-404.png"
            alt="error 404"
            width={306}
            height={166}
          />
          <h2>Halaman yang kamu akses tidak bisa ditemukan.</h2>
          <p>
            Tidak bisa akses halaman? bisa jadi ada kesalahan nih. Silahkan periksa URL kamu atau
            <a href="https://www.harisenin.com">coba lagi</a>.
          </p>
        </div>
      </div>
    </>
  )
}

export default NotFoundPage
